import React from "react";

export const Footer: React.FC = () => (
  <footer className="text-center text-gray-600 py-8">
    <p>
      Created by{" "}
      <a
        href="https://www.linkedin.com/in/thomas-boyd-61875729/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-600 hover:text-blue-800 transition-colors"
      >
        Thomas Boyd
      </a>
    </p>
    <p>
      <a
        href="https://www.flaticon.com/free-icons/thunder"
        title="thunder icons"
      >
        Thunder icons created by Freepik - Flaticon
      </a>
    </p>
  </footer>
);
