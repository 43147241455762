import energyScreen from "../assets/energy-screen.jpg";
import React from "react";

interface InstructionStepProps {
  number: number;
  title: string;
  items: Array<string | { label: string; description: string }>;
  showImage?: boolean;
}

export const InstructionStep: React.FC<InstructionStepProps> = ({
  number,
  title,
  items,
  showImage,
}) => (
  <li className="bg-blue-50 rounded-lg p-4">
    <h3 className="text-lg font-semibold text-blue-800 mb-2">
      {number}. {title}
    </h3>
    <ul className="list-disc list-inside text-gray-700 space-y-3 ml-4">
      {items.map((item, index) =>
        typeof item === "string" ? (
          <li key={index}>{item}</li>
        ) : (
          <li key={index} className="flex flex-col">
            <span className="font-medium text-blue-900">{item.label}:</span>
            <span className="text-gray-600 ml-4">{item.description}</span>
          </li>
        )
      )}
    </ul>
    {showImage && (
      <div className="mt-4">
        <img className="rounded-lg" src={energyScreen} alt="Energy screen" />
      </div>
    )}
  </li>
);
