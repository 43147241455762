import React from "react";

interface InputFieldProps {
  label: string;
  name: string;
  onChange: (value: number) => void;
  placeholder: string;
}

export const InputField: React.FC<InputFieldProps> = ({
  label,
  name,
  onChange,
  placeholder,
}) => (
  <div className="space-y-1">
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <input
      type="text"
      name={name}
      maxLength={4}
      onChange={(event) => onChange(parseInt(event.target.value, 10))}
      placeholder={placeholder}
      aria-label={name}
      className="w-full p-2 border border-gray-300 rounded-md bg-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
    />
  </div>
);
