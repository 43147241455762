import React from "react";
import { InstructionStep } from "./InstructionStep";

export const InstructionsContent: React.FC = () => (
  <div className="p-4 pt-6">
    <p className="text-gray-600 mb-6">
      To make the most of the Tesla Battery Checker tool, follow these steps to
      gather the necessary data:
    </p>
    <ol className="space-y-6">
      <InstructionStep
        number={1}
        title="Navigate to Your Energy Graph"
        items={[
          "Open your Tesla's display screen and access the 'Energy' app",
          "Switch to the 'Consumption' tab",
        ]}
      />
      <InstructionStep
        number={2}
        title="Set Range to 30 Miles / 50 Kilometers"
        items={[
          'In the energy graph, make sure the distance range is set to "30 miles" or "50 kilometers"',
        ]}
      />
      <InstructionStep
        number={3}
        title="Note Key Metrics"
        items={[
          {
            label: "Average watt hours",
            description:
              "Note the average watt-hours per mile (Wh/mi) or watt-hours per kilometer (Wh/km) value on the energy graph",
          },
          {
            label: "Projected Range",
            description:
              "Note the projected range value displayed on the energy graph",
          },
          {
            label: "Battery Percentage",
            description: "Note the current battery percentage",
          },
        ]}
        showImage
      />
      <InstructionStep
        number={4}
        title="Enter your values"
        items={[
          {
            label: "Your vehicle details",
            description: "Enter the values you noted in Step 3",
          },
          { label: "Model selection", description: "Choose your model" },
        ]}
      />
    </ol>

    <div className="mt-6 bg-green-50 rounded-lg p-4">
      <p className="text-green-800 font-medium">
        Please note the model battery capacity values are based on estimated
        usable battery when new.
      </p>
    </div>
  </div>
);
