import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import { InstructionsContent } from "./InstructionContent";

export const Instructions: React.FC = () => {
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(false);

  return (
    <div className="bg-white rounded-lg mt-8 mb-8">
      <button
        onClick={() => setIsInstructionsOpen(!isInstructionsOpen)}
        className="w-full p-3 pb-4 flex items-center justify-center text-left hover:bg-gray-100 transition-colors duration-150"
      >
        <h2 className="text-xl font-semibold text-gray-800">
          How to use Tesla Battery Check
        </h2>
        <span className="text-gray-500 ml-2">
          {isInstructionsOpen ? (
            <ChevronUp size={24} />
          ) : (
            <ChevronDown size={24} />
          )}
        </span>
      </button>

      <div
        className={`transition-all duration-300 ease-in-out overflow-hidden ${
          isInstructionsOpen
            ? "max-h-[2000px] opacity-100"
            : "max-h-0 opacity-0"
        }`}
      >
        <InstructionsContent />
      </div>
    </div>
  );
};
