import { BatteryInfo } from "../data/data";

export const sortedVariants = (variants: BatteryInfo[]) => {
  return variants.sort((a, b) => {
    const variantA = a.variant;
    const variantB = b.variant;
    const variantAMatch = variantA.match(/\d+/);
    const variantBMatch = variantB.match(/\d+/);
    // Extract base number (70, 85, 90)
    const numA = parseInt(variantAMatch ? variantAMatch[0] : "0");
    const numB = parseInt(variantBMatch ? variantBMatch[0] : "0");

    if (numA !== numB) return numB - numA;

    // If numbers are equal, sort by performance/drivetrain
    // P (Performance) > D (Dual Motor) > Base
    if (variantA.startsWith("P") && !variantB.startsWith("P")) return -1;
    if (!variantA.startsWith("P") && variantB.startsWith("P")) return 1;
    if (variantA.includes("D") && !variantB.includes("D")) return -1;
    if (!variantA.includes("D") && variantB.includes("D")) return 1;
    if (variantA.includes("L") && !variantB.includes("L")) return -1;
    if (!variantA.includes("L") && variantB.includes("L")) return 1;

    return 0;
  });
};
