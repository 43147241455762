// components/ModelSelection.tsx
import React from "react";
import ModelSelector from "./ModelSelector";

interface ModelSelectionProps {
  setModelCapacity: (capacity: number | null) => void;
  setModel: (model: string | null) => void;
}

export const ModelSelection: React.FC<ModelSelectionProps> = ({
  setModelCapacity,
  setModel,
}) => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
    <div className="bg-blue-600 px-4 py-3">
      <h2 className="text-lg font-semibold text-white">Model selection</h2>
    </div>
    <div className="p-4">
      <ModelSelector setModelCapacity={setModelCapacity} setModel={setModel} />
    </div>
  </div>
);
