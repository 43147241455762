export interface BatteryInfo {
  variant: string;
  years: string;
  capacityKwh: number;
  yearList: number[];
}

interface TeslaBatteryData {
  [key: string]: BatteryInfo[];
}

export const teslaBatteryData: TeslaBatteryData = {
  "Model S": [
    {
      variant: "85",
      capacityKwh: 80.8,
      years: "2013-2016",
      yearList: [2013, 2014, 2015, 2016],
    },
    {
      variant: "P85D",
      capacityKwh: 80.8,
      years: "2014-2016",
      yearList: [2014, 2015, 2016],
    },
    {
      variant: "70",
      capacityKwh: 66.5,
      years: "2015-2016",
      yearList: [2015, 2016],
    },
    {
      variant: "70D",
      capacityKwh: 66.5,
      years: "2015-2016",
      yearList: [2015, 2016],
    },
    {
      variant: "85D",
      capacityKwh: 80.8,
      years: "2015-2016",
      yearList: [2015, 2016],
    },
    {
      variant: "90D",
      capacityKwh: 85.5,
      years: "2015-2016",
      yearList: [2015, 2016],
    },
    {
      variant: "P90DL",
      capacityKwh: 85.5,
      years: "2015-2016",
      yearList: [2015, 2016],
    },
    {
      variant: "P90D",
      capacityKwh: 85.5,
      years: "2015-2016",
      yearList: [2015, 2016],
    },
    {
      variant: "70",
      capacityKwh: 69,
      years: "2016-2016",
      yearList: [2016],
    },
    {
      variant: "70D",
      capacityKwh: 69,
      years: "2016-2016",
      yearList: [2016],
    },
    {
      variant: "90D",
      capacityKwh: 85.5,
      years: "2016-2017",
      yearList: [2016, 2017],
    },
    {
      variant: "75",
      capacityKwh: 72.5,
      years: "2016-2017",
      yearList: [2016, 2017],
    },
    {
      variant: "75D",
      capacityKwh: 72.5,
      years: "2016-2019",
      yearList: [2016, 2017, 2018, 2019],
    },
    {
      variant: "60D",
      capacityKwh: 62,
      years: "2016-2017",
      yearList: [2016, 2017],
    },
    {
      variant: "60",
      capacityKwh: 62,
      years: "2016-2017",
      yearList: [2016, 2017],
    },
    {
      variant: "P100D",
      capacityKwh: 95,
      years: "2016-2019",
      yearList: [2016, 2017, 2018, 2019],
    },
    {
      variant: "100D",
      capacityKwh: 95,
      years: "2017-2019",
      yearList: [2017, 2018, 2019],
    },
    {
      variant: "Standard Range",
      capacityKwh: 72.5,
      years: "2019-2019",
      yearList: [2019],
    },
    {
      variant: "Performance",
      capacityKwh: 95,
      years: "2019-2020",
      yearList: [2019, 2020],
    },
    {
      variant: "Long Range",
      capacityKwh: 95,
      years: "2019-2020",
      yearList: [2019, 2020],
    },
    {
      variant: "Performance",
      capacityKwh: 98,
      years: "2020-2021",
      yearList: [2020, 2021],
    },
    {
      variant: "Long Range Plus",
      capacityKwh: 98,
      years: "2020-2021",
      yearList: [2020, 2021],
    },
    {
      variant: "Dual Motor",
      capacityKwh: 95,
      years: "2023-",
      yearList: [2023, 2024],
    },
    {
      variant: "Plaid",
      capacityKwh: 95,
      years: "2022-",
      yearList: [2022, 2023, 2024],
    },
  ],
  "Model X": [
    {
      variant: "60D",
      capacityKwh: 62,
      years: "2016-2017",
      yearList: [2016, 2017],
    },
    {
      variant: "75D",
      capacityKwh: 72.5,
      years: "2016-2018",
      yearList: [2016, 2017, 2018],
    },
    {
      variant: "P90DL",
      capacityKwh: 85.5,
      years: "2016-2016",
      yearList: [2016],
    },
    {
      variant: "P90D",
      capacityKwh: 85.5,
      years: "2016-2016",
      yearList: [2016],
    },
    {
      variant: "90D",
      capacityKwh: 85.5,
      years: "2016-2017",
      yearList: [2016, 2017],
    },
    {
      variant: "P100D",
      capacityKwh: 95,
      years: "2017-2019",
      yearList: [2017, 2018, 2019],
    },
    {
      variant: "100D",
      capacityKwh: 95,
      years: "2017-2019",
      yearList: [2017, 2018, 2019],
    },
    {
      variant: "Performance",
      capacityKwh: 95,
      years: "2019-2019",
      yearList: [2019],
    },
    {
      variant: "Ludicrous Performance",
      capacityKwh: 95,
      years: "2019-2019",
      yearList: [2019],
    },
    {
      variant: "Long Range",
      capacityKwh: 95,
      years: "2019-2020",
      yearList: [2019, 2020],
    },
    {
      variant: "Standard Range",
      capacityKwh: 72.5,
      years: "2019-2019",
      yearList: [2019],
    },
    {
      variant: "Performance",
      capacityKwh: 98,
      years: "2020-2021",
      yearList: [2020, 2021],
    },
    {
      variant: "Long Range Plus",
      capacityKwh: 98,
      years: "2020-2021",
      yearList: [2020, 2021],
    },
    {
      variant: "Dual Motor",
      capacityKwh: 95,
      years: "2023-",
      yearList: [2023, 2024],
    },
    {
      variant: "Plaid",
      capacityKwh: 95,
      years: "2022-",
      yearList: [2022, 2023, 2024],
    },
  ],
  "Model 3": [
    {
      variant: "Long Range Performance",
      capacityKwh: 73.5,
      years: "2019-2020",
      yearList: [2019, 2020],
    },
    {
      variant: "Long Range Dual Motor",
      capacityKwh: 73.5,
      years: "2019-2020",
      yearList: [2019, 2020],
    },
    {
      variant: "Standard Range Plus",
      capacityKwh: 49,
      years: "2019-2020",
      yearList: [2019, 2020],
    },
    {
      variant: "Long Range RWD",
      capacityKwh: 73.5,
      years: "2019-2019",
      yearList: [2019],
    },
    {
      variant: "Standard Range Plus LFP",
      capacityKwh: 52.5,
      years: "2020-2021",
      yearList: [2020, 2021],
    },
    {
      variant: "Long Range Dual Motor",
      capacityKwh: 72,
      years: "2020-2022",
      yearList: [2020, 2021, 2022],
    },
    {
      variant: "Performance",
      capacityKwh: 76,
      years: "2020-2022",
      yearList: [2020, 2021, 2022],
    },
    {
      variant: "Standard Range Plus",
      capacityKwh: 51,
      years: "2021-2021",
      yearList: [2021],
    },
    {
      variant: "Long Range Dual Motor",
      capacityKwh: 76,
      years: "2021-2021",
      yearList: [2021],
    },
    {
      variant: "Long Range Dual Motor",
      capacityKwh: 75,
      years: "2021-2023",
      yearList: [2021, 2022, 2023],
    },
    {
      variant: "Model 3",
      capacityKwh: 57.5,
      years: "2021-2023",
      yearList: [2021, 2022, 2023],
    },
    {
      variant: "Performance",
      capacityKwh: 75,
      years: "2022-2023",
      yearList: [2022, 2023],
    },
    {
      variant: "Long Range RWD",
      capacityKwh: 75,
      years: "2023-2023",
      yearList: [2023],
    },
    {
      variant: "Model 3",
      capacityKwh: 57.5,
      years: "2023-",
      yearList: [2023, 2024],
    },
    {
      variant: "Long Range Dual Motor",
      capacityKwh: 75,
      years: "2023-",
      yearList: [2023, 2024],
    },
    {
      variant: "Long Range RWD",
      capacityKwh: 75,
      years: "2024-",
      yearList: [2024],
    },
    {
      variant: "Performance",
      capacityKwh: 75,
      years: "2024-",
      yearList: [2024],
    },
  ],
  "Model Y": [
    {
      variant: "Long Range Dual Motor",
      capacityKwh: 72,
      years: "2021-2022",
      yearList: [2021, 2022],
    },
    {
      variant: "Long Range Dual Motor",
      capacityKwh: 75,
      years: "2022-",
      yearList: [2022, 2023, 2024],
    },
    {
      variant: "Model Y",
      capacityKwh: 57.5,
      years: "2022-",
      yearList: [2022, 2023, 2024],
    },
    {
      variant: "Long Range RWD",
      capacityKwh: 75,
      years: "2024-",
      yearList: [2024],
    },
    {
      variant: "Performance",
      capacityKwh: 75,
      years: "2022-",
      yearList: [2022, 2023, 2024],
    },
    {
      variant: "Model Y",
      capacityKwh: 57.5,
      years: "2023-",
      yearList: [2023, 2024],
    },
  ],
};
