import React from "react";
import { Helmet } from "react-helmet";
import Citation from "../components/Citation";

const WarningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    className="text-amber-600"
  >
    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
    <line x1="12" y1="9" x2="12" y2="13" />
    <line x1="12" y1="17" x2="12.01" y2="17" />
  </svg>
);

const BatteryManagement: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          Tesla Battery Management & Care Guide: Tips for Maximum Lifespan
        </title>
        <meta
          name="description"
          content="Comprehensive guide to Tesla battery care: optimal charging practices, temperature management, and storage recommendations backed by scientific research."
        />
        <meta
          name="keywords"
          content="Tesla battery management, EV charging best practices, Tesla battery care, battery temperature management, Tesla storage tips"
        />
        <meta
          property="og:title"
          content="Tesla Battery Management & Care Guide"
        />
        <meta
          property="og:description"
          content="Expert guide on maximizing Tesla battery life through proper management and care."
        />
      </Helmet>

      <article className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="visually-hidden">
          Maximising your cars battery health and longevity
        </h1>
        <div className="flex justify-center w-full">
          <p className="w-4/5 text-center mt-6 mb-12 text-3xl">
            Use these tips to maximise your Teslas battery range and ensure the
            long term health of your battery pack.
          </p>
        </div>
        <div className="bg-amber-50 border-l-4 border-amber-600 p-4 mb-8">
          <div className="flex">
            <div className="flex-shrink-0">
              <WarningIcon />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-amber-800">
                Important Note
              </h3>
              <p className="text-sm text-amber-700 mt-1">
                These guidelines are based on a combination of Tesla's official
                recommendations and recommendations generally accepted in the EV
                community.
              </p>
            </div>
          </div>
        </div>

        <div className="space-y-8">
          <section className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              How to charge effectively for maximum battery longevity{" "}
              <Citation id="1" />
            </h2>
            <div className="space-y-6">
              <h3 className="text-xl font-medium text-gray-800 mb-3">
                Best Practices
              </h3>
              <div>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex space-x-2">
                    <span>•</span>
                    <span>Maintain charge between 20-80%</span>
                  </li>
                  <li className="flex space-x-2">
                    <span>•</span>
                    <div>
                      <p>Limit 100% charges to:</p>
                      <ul className="ml-4 mt-2 space-y-2">
                        <li>
                          - Long trips, as an example charging to 100% the
                          morning before a road trip
                        </li>
                        <li>- Battery calibration scenarios</li>
                      </ul>
                    </div>
                  </li>
                  <li className="flex space-x-2">
                    <span>•</span>
                    <div>
                      <p>Fast charging limitations:</p>
                      <ul className="ml-4 mt-2 space-y-2">
                        <li>
                          - Extreme heat and cold conditions combined with rapid
                          charging can exacerbate battery degredation
                        </li>
                        <li>
                          - Frequent rapid charging may also lead to exacerbated
                          battery degredation
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="overflow-x-auto">
                <h3 className="text-xl font-medium text-gray-800 mb-3">
                  Charging speed guidelines
                </h3>
                <table className="min-w-full text-sm mt-4 bg-white border border-gray-300 rounded-lg">
                  <thead>
                    <tr className="bg-gray-200 text-gray-700">
                      <th className="py-2 px-4 text-left border-b">
                        Charging Type
                      </th>
                      <th className="py-2 px-4 text-left border-b">
                        Speed (Range Added)
                      </th>
                      <th className="py-2 px-4 text-left border-b">
                        Battery Impact
                      </th>
                      <th className="py-2 px-4 text-left border-b">
                        Charging time
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600">
                    <tr className="border-b">
                      <td className="py-2 px-4">Level 1 (120V)</td>
                      <td className="py-2 px-4">3-7 miles/hour</td>
                      <td className="py-2 px-4">Minimal</td>
                      <td className="py-2 px-4">~24 hours</td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4">Level 2 (240V)</td>
                      <td className="py-2 px-4">15-35 miles/hour</td>
                      <td className="py-2 px-4">Low</td>
                      <td className="py-2 px-4">4-8 hours</td>
                    </tr>
                    <tr>
                      <td className="py-2 px-4">DC Fast Charging</td>
                      <td className="py-2 px-4">200+ miles/hour</td>
                      <td className="py-2 px-4">Higher</td>
                      <td className="py-2 px-4">45 minutes to an hour</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>

          <section className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              How temperature affects your Tesla <Citation id="2" />
            </h2>
            <div className="space-y-4 text-gray-600">
              <div>
                <h3 className="text-xl font-medium text-gray-800 mb-3">
                  Impact on Performance
                </h3>
                <ul className="space-y-3">
                  <li className="flex space-x-2">
                    <span>•</span>
                    <span>
                      Ideal operating range: 15°C to 35°C (59°F to 95°F)
                    </span>
                  </li>
                  <li className="flex space-x-2">
                    <span>•</span>
                    <div>
                      <p>
                        Temperature impact on range: <Citation id="3" />
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <table className="min-w-full text-sm mt-4 bg-white border border-gray-300 rounded-lg">
                  <thead>
                    <tr className="bg-gray-200 text-gray-700">
                      <th className="py-2 px-4 text-left border-b">
                        Temperature (°F)
                      </th>
                      <th className="py-2 px-4 text-left border-b">
                        Temperature (°C)
                      </th>
                      <th className="py-2 px-4 text-left border-b">
                        Range Loss (%)
                      </th>
                      <th className="py-2 px-4 text-left border-b">
                        Range with 200 Mile Battery
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600">
                    <tr className="border-b">
                      <td className="py-2 px-4">70°F</td>
                      <td className="py-2 px-4">21°C</td>
                      <td className="py-2 px-4">0%</td>
                      <td className="py-2 px-4">200 miles</td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4">40°F</td>
                      <td className="py-2 px-4">4°C</td>
                      <td className="py-2 px-4">10%</td>
                      <td className="py-2 px-4">180 miles</td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4">25-30°F</td>
                      <td className="py-2 px-4">-4 to -1°C</td>
                      <td className="py-2 px-4">16.5%</td>
                      <td className="py-2 px-4">167 miles</td>
                    </tr>
                    <tr>
                      <td className="py-2 px-4">15-20°F</td>
                      <td className="py-2 px-4">-9 to -6°C</td>
                      <td className="py-2 px-4">27.3%</td>
                      <td className="py-2 px-4">145 miles</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mt-4">
                <h3 className="text-xl font-medium text-gray-800 mb-3">
                  Impact on Capacity
                </h3>
                <ul className="space-y-3">
                  <li className="flex space-x-2">
                    <span>•</span>
                    <div>
                      Cold temperatures generally only limit range whilst it's
                      cold
                    </div>
                  </li>
                  <li className="flex space-x-2">
                    <span>•</span>
                    <div>
                      High temperatures can degrade the battery and lead to
                      permanant loss of capacity
                    </div>
                  </li>
                  <li className="flex space-x-2">
                    <span>•</span>
                    <div>
                      In hotter weather a higher charge level will have a
                      pronounced affect on battery degredation
                    </div>
                  </li>
                </ul>
              </div>
              <div className="mt-4">
                <h3 className="text-xl font-medium text-gray-800 mb-3">
                  Preconditioning Guidelines <Citation id="4" />
                </h3>
                <ul className="space-y-3">
                  <li className="flex space-x-2">
                    <span>•</span>
                    <div>
                      You can precondition your car by opening the Tesla app,
                      clicking on "Climate" and turning on the heater
                    </div>
                  </li>
                  <li className="flex space-x-2">
                    <span>•</span>
                    <div>
                      It's recommended to turn this on 30-45 minutes before
                      departure
                    </div>
                  </li>
                  <li className="flex space-x-2">
                    <span>•</span>
                    <div>Target a temperature of 21°C (70°F)</div>
                  </li>
                  <li className="flex space-x-2">
                    <span>•</span>
                    <div>
                      Precondition whilst charging if possible, this is a power
                      hungry task
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Long-Term Storage Protocol
            </h2>
            <div className="space-y-4 text-gray-600">
              <ul className="space-y-3">
                <li className="flex space-x-2">
                  <span>•</span>
                  <span>Maintain 50% ±10% charge level (3.8V per cell)</span>
                </li>
                <li className="flex space-x-2">
                  <span>•</span>
                  <div>
                    <p>Environmental requirements:</p>
                    <ul className="ml-4 mt-2 space-y-2">
                      <li>- Temperature: 10-20°C (50-68°F)</li>
                      <li>- Humidity: Below 85% RH</li>
                      <li>- Avoid direct sunlight exposure</li>
                    </ul>
                  </div>
                </li>
                <li className="flex space-x-2">
                  <span>•</span>
                  <div>
                    <p>Maintenance schedule:</p>
                    <ul className="ml-4 mt-2 space-y-2">
                      <li>- Monthly charge level checks</li>
                      <li>- Recharge when below 40%</li>
                      <li>- Quarterly physical inspection</li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </section>

          <footer className="text-sm text-gray-500 mt-8">
            <h2 className="font-semibold mb-2">References</h2>
            <ol className="list-decimal ml-6 space-y-1">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://optiwatt.com/blog/the-comprehensive-guide-to-maximizing-your-teslas-battery-efficiency-and-battery-life"
                >
                  How to Maximize your Tesla's Battery Efficiency and Life
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.evcreate.com/ideal-battery-temperature/#:~:text=Instead%20the%20electric%20vehicle%20should,C%20to%2035%20%C2%B0C"
                >
                  EVCreate: Ideal battery temperature?
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.cars.com/articles/our-tesla-model-y-in-cold-weather-how-was-range-affected-448043/"
                >
                  Our Tesla Model Y in Cold Weather: How Was Range Affected?
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.tesla.com/ownersmanual/2015_2020_modelx/en_us/GUID-F907200E-A619-4A95-A0CF-94E0D03BEBEF.html#:~:text=Tesla%20recommends%20activating%20climate%20settings,reached%20the%20desired%20preconditioning%20temperature"
                >
                  Cold Weather Best Practices
                </a>
              </li>

              {/*<li>
                <a
                  target="_blank"
                  href="https://1charging.com/the-80-20-ev-charging-rule-ev-battery-charging-best-practices/"
                >
                  The 80/20 EV Charging Rule: EV Battery Charging Best Practices
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://wattlogic.com/blog/how-often-should-i-charge-my-ev-to-100/"
                >
                  How Often Should I Charge My EV to 100%?
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.energysage.com/ev-charging/ev-charging-best-practices/"
                >
                  EV charging best practices: How can you keep your battery
                  healthy?
                </a>
              </li>
              <li>
                Johnson, et al. "Temperature Effects on Li-ion Battery
                Performance." Nature Energy, 2023
              </li>
              <li>
                National Renewable Energy Laboratory. "EV Range Analysis Under
                Variable Climate Conditions." Technical Report
                NREL/TP-5400-82501, 2023
              </li>
              <li>
                Kim, et al. "Optimal Preconditioning Strategies for EV
                Batteries." Journal of Energy Storage, 2023
              </li>
              <li>
                Department of Energy. "Best Practices for EV Battery Storage."
                DOE/EE-2514, 2023
              </li>*/}
            </ol>
          </footer>
        </div>
      </article>
    </>
  );
};

export default BatteryManagement;
