import React, { useState, useMemo } from "react";
import { teslaBatteryData, BatteryInfo } from "../data/data";
import { sortedVariants } from "../helpers";

interface ModelSelectorProps {
  setModelCapacity: (capacity: number | null) => void;
  setModel: (model: string | null) => void;
}

const ModelSelector: React.FC<ModelSelectorProps> = ({
  setModelCapacity,
  setModel,
}) => {
  const [selectedModel, setSelectedModel] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<number | "">("");
  const [selectedVariant, setSelectedVariant] = useState<string>("");
  const [batteryInfo, setBatteryInfo] = useState<BatteryInfo | null>(null);
  const [customCapacity, setCustomCapacity] = useState<string>("");
  const [isEdited, setIsEdited] = useState(false);

  // Get unique years for selected model
  const availableYears = useMemo(() => {
    if (!selectedModel) return [];
    const years = new Set<number>();
    teslaBatteryData[selectedModel].forEach((variant) => {
      variant.yearList.forEach((year) => years.add(year));
    });
    return Array.from(years);
  }, [selectedModel]);

  // Filter and deduplicate variants based on selected year
  const availableVariants = useMemo(() => {
    if (!selectedModel || !selectedYear) return [];

    // Create a Map using variant name as key to ensure uniqueness
    const variantMap = new Map();

    teslaBatteryData[selectedModel]
      .filter((variant) => variant.yearList.includes(selectedYear))
      .forEach((variant) => {
        // Only add if we haven't seen this variant name before
        if (!variantMap.has(variant.variant)) {
          variantMap.set(variant.variant, variant);
        }
      });

    // Convert Map values back to array and sort
    return sortedVariants(Array.from(variantMap.values())).reverse();
  }, [selectedModel, selectedYear]);

  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const model = e.target.value;
    setSelectedModel(model);
    setSelectedYear("");
    setSelectedVariant("");
    setBatteryInfo(null);
    setCustomCapacity("");
    setIsEdited(false);
    setModelCapacity(null);
    setModel(null);
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const year = parseInt(e.target.value) || "";
    setSelectedYear(year);
    setSelectedVariant("");
    setBatteryInfo(null);
    setCustomCapacity("");
    setIsEdited(false);
    setModelCapacity(null);
    setModel(null);
  };

  const handleVariantChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const variant = e.target.value;
    setSelectedVariant(variant);
    const variantInfo = availableVariants.find((v) => v.variant === variant);
    if (variantInfo) {
      setBatteryInfo(variantInfo);
      setCustomCapacity(variantInfo.capacityKwh.toString());
      setModelCapacity(variantInfo.capacityKwh);
      setIsEdited(false);
      setModel(selectedModel);
    }
  };

  const handleCapacityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCustomCapacity(value);
    setIsEdited(true);
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue)) {
      setModelCapacity(numericValue);
    }
  };

  const handleReset = () => {
    if (batteryInfo) {
      setCustomCapacity(batteryInfo.capacityKwh.toString());
      setModelCapacity(batteryInfo.capacityKwh);
      setIsEdited(false);
    }
  };

  return (
    <div>
      <div className="space-y-4">
        {/* Model Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select Model:
          </label>
          <select
            value={selectedModel}
            onChange={handleModelChange}
            className="w-full p-2 border border-gray-300 rounded-md bg-white shadow-sm focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Choose a model</option>
            {Object.keys(teslaBatteryData).map((model) => (
              <option key={model} value={model}>
                {model}
              </option>
            ))}
          </select>
        </div>

        {/* Year Selection */}
        {selectedModel && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Year:
            </label>
            <select
              value={selectedYear}
              onChange={handleYearChange}
              className="w-full p-2 border border-gray-300 rounded-md bg-white shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Choose a year</option>
              {availableYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Variant Selection */}
        {selectedYear && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Variant:
            </label>
            <select
              value={selectedVariant}
              onChange={handleVariantChange}
              className="w-full p-2 border border-gray-300 rounded-md bg-white shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Choose a variant</option>
              {availableVariants.map((variant) => (
                <option key={variant.variant} value={variant.variant}>
                  {variant.variant}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Battery Information */}
        {batteryInfo && (
          <div className="mt-6 p-4 bg-gray-50 rounded-md border border-gray-200">
            <h3 className="text-lg font-semibold mb-3 text-gray-800">
              Battery Information
            </h3>
            <div className="space-y-2">
              <p className="text-gray-700">
                <span className="font-medium">Model:</span> {selectedModel}
              </p>
              <p className="text-gray-700">
                <span className="font-medium">Year:</span> {selectedYear}
              </p>
              <p className="text-gray-700">
                <span className="font-medium">Variant:</span>{" "}
                {batteryInfo.variant}
              </p>
              <div>
                <div className="flex font-medium text-gray-700 mb-2">
                  Battery Capacity *:
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="number"
                    value={customCapacity}
                    onChange={handleCapacityChange}
                    className="w-24 p-1 text-right border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  />
                  <span className="text-gray-700">kWh</span>
                  {isEdited && (
                    <button
                      onClick={handleReset}
                      className="p-1 text-gray-500 hover:text-blue-600 hover:bg-gray-100 rounded-md transition-colors"
                      title="Reset to original value"
                    >
                      Reset
                    </button>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-xs">
                  * estimated usable battery capacity when new
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModelSelector;
