import React from "react";
import { Helmet } from "react-helmet";

const BatteryInfo: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          Tesla Battery Technology Guide: Specs, Lifespan & Warranty | 2024
        </title>
        <meta
          name="description"
          content="Comprehensive guide to Tesla's battery technology: detailed specifications, lifespan analysis, and warranty information. Learn about cell chemistry, thermal management, and performance metrics."
        />
        <meta
          name="keywords"
          content="Tesla battery, lithium-ion, battery lifespan, EV battery warranty, Model S battery, Model 3 battery, battery degradation"
        />
        <meta property="og:title" content="Tesla Battery Technology Guide" />
        <meta
          property="og:description"
          content="In-depth analysis of Tesla battery technology, specifications, and warranty coverage."
        />
      </Helmet>

      <article className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-6">
          Tesla Battery Technology
        </h1>

        <div className="space-y-8">
          <section className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Battery Technology & Cell Chemistry
            </h2>
            <div className="prose text-gray-600">
              <p className="mb-4">
                Tesla vehicles utilize advanced lithium-ion battery technology
                with specific chemistry variations depending on the model and
                manufacturing date. Current generation vehicles primarily use
                NMC (Nickel Manganese Cobalt) and LFP (Lithium Iron Phosphate)
                chemistry.
              </p>
              <h3 className="text-xl font-medium text-gray-800 mt-4 mb-2">
                Key Specifications:
              </h3>
              <ul className="list-disc ml-6 space-y-2">
                <li>
                  Energy Density: 272-296 Wh/kg (Model 3/Y with 2170 cells)
                  <sup>1</sup>
                </li>
                <li>Voltage: 350-405V nominal (varying by model)</li>
                <li>
                  Cell Types:
                  <ul className="list-circle ml-6 mt-1">
                    <li>2170 cells (Model 3/Y): 21mm x 70mm</li>
                    <li>4680 cells (newest generation): 46mm x 80mm</li>
                    <li>18650 cells (older Model S/X): 18mm x 65mm</li>
                  </ul>
                </li>
              </ul>
            </div>
          </section>

          <section className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Thermal Management System
            </h2>
            <div className="prose text-gray-600">
              <p className="mb-4">
                Tesla's proprietary thermal management system maintains optimal
                battery temperature through:
              </p>
              <ul className="list-disc ml-6 space-y-2">
                <li>
                  Liquid coolant circulation through ribbon-style cooling tubes
                </li>
                <li>Active temperature monitoring with multiple sensors</li>
                <li>
                  Intelligent thermal preconditioning for optimal charging
                </li>
                <li>
                  Operating temperature range: -30°C to 45°C (-22°F to 113°F)
                  <sup>2</sup>
                </li>
              </ul>
            </div>
          </section>

          <section className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Battery Lifespan & Degradation
            </h2>
            <div className="prose text-gray-600">
              <p className="mb-4">
                Tesla batteries are engineered for longevity, with real-world
                data showing impressive retention of capacity:
              </p>
              <ul className="list-disc ml-6 space-y-2">
                <li>
                  Expected lifespan: 300,000-500,000 miles (21-35 years for
                  average driver)
                </li>
                <li>
                  Typical degradation: 5% in first 50,000 miles, then 1-2% per
                  50,000 miles thereafter<sup>3</sup>
                </li>
                <li>
                  10-year capacity retention: ~90% under normal usage conditions
                </li>
              </ul>
            </div>
          </section>

          <section className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Warranty Coverage Details
            </h2>
            <div className="prose text-gray-600">
              <p className="mb-4">
                Tesla provides comprehensive warranty coverage for their battery
                and drive unit:
              </p>
              <ul className="list-disc ml-6 space-y-2">
                <li>
                  Model S/X:
                  <ul className="list-circle ml-6 mt-1">
                    <li>8 years or 150,000 miles</li>
                    <li>Minimum 70% retention of battery capacity</li>
                  </ul>
                </li>
                <li>
                  Model 3/Y:
                  <ul className="list-circle ml-6 mt-1">
                    <li>8 years or 120,000 miles</li>
                    <li>Minimum 70% retention of battery capacity</li>
                    <li>Standard Range: 8 years or 100,000 miles</li>
                  </ul>
                </li>
              </ul>
            </div>
          </section>

          <footer className="text-sm text-gray-500 mt-8">
            <p className="mb-2">Sources:</p>
            <ol className="list-decimal ml-6 space-y-1">
              <li>Tesla Battery Day Presentation, 2020</li>
              <li>Tesla Model 3 Owner's Manual, 2024 Edition</li>
              <li>Tesla Fleet Data Analysis, 2023</li>
            </ol>
          </footer>
        </div>
      </article>
    </>
  );
};

export default BatteryInfo;
